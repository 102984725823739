._3GnVl {
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    opacity: .5;
/*    background-color:pink;*/
}


._3GnVl, ._37_FJ {
    position: absolute
}

._37_FJ {
    z-index: 1;
    left: 50% !important;
    top: 50% !important;
    height: 50% !important;
    width: 100% !important;
    height: 100% !important;
    /*    width: 500px;*/
    /*    max-width: 400px;*/
    transform: translate(-50%,-50%);
    border: none;
    border-radius: 5px !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: stretch;
    align-content: center;
    background-color: #000000;
    color: rgb(35,31,32);
    border-radius: 10px !important;
    font-family: monospace;
    padding: 20%;
    margin-top: 30%;
    /*    padding-top: 20px;*/
    /*    padding-top: 20px;*/
}

    ._37_FJ > div {
        padding: 10px !important;
        height: 100% !important;
        margin-top: 30px !important;
        margin-bottom: 30px !important;
    }

    ._3GnV1 canvas {
/*        background-color: rgb(35,31,32) !important;*/
        background-color: pink !important;
        width:0px !important;
        height:0px !important;
        color: rgb(35,31,32) !important;
        visibility:hidden;
    }

._37_FJ input, ._37_FJ label {
    color: white !important;
    background-color: transparent;
    border-radius: 10px;
    margin-left: auto;
    margin-right: auto;
    display: block;
    text-align: center;
    width: 100px !important;
    height: 30px;
    border: none;
    font-family: monospace;
}

._37_FJ button {
    color: white !important;
    background-color: transparent;
    border-radius: 10px !important;
    margin-left: auto;
    margin-right: auto;
    display: block;
    text-align: center;
    width: 100px !important;
    height: 30px !important;
    border: solid 1px white;
    font-family: monospace;
    box-shadow: 0 .25rem .75rem rgba(255, 255, 255, .2);
}

._ovIFV {
    background-color: transparent;
    color: white;
    font-size: x-large; 
    border-radius:10px;
    text-align:center;
}

._1YwH3 {
    align-self: flex-end
}

._3O418 {
    width: 40px;
    height: 40px;
    -webkit-animation: _1SVAc 2.5s linear infinite both;
    animation: _1SVAc 2.5s linear infinite both;
    left: 50%;
    top: 50%;
    border-radius:10px;
}

._1djK_, ._3O418 {
    position: absolute
}

._1djK_ {
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
/*    -webkit-animation: _1djK_ 2s ease-in-out infinite both;
    animation: _1djK_ 2s ease-in-out infinite both*/
}

    ._1djK_:before {
        content: "";
        display: block;
        width: 25%;
        height: 25%;
        background-color: #000000;
        border-radius: 100%;
        /*        -webkit-animation: _1292Q 2s ease-in-out infinite both;
        animation: _1292Q 2s ease-in-out infinite both*/
    }

    ._1djK_:first-child {
        -webkit-animation-delay: -1.1s;
        animation-delay: -1.1s
    }

    ._1djK_:nth-child(2) {
        -webkit-animation-delay: -1s;
        animation-delay: -1s
    }

    ._1djK_:nth-child(3) {
        -webkit-animation-delay: -.9s;
        animation-delay: -.9s
    }

    ._1djK_:nth-child(4) {
        -webkit-animation-delay: -.8s;
        animation-delay: -.8s
    }

    ._1djK_:nth-child(5) {
        -webkit-animation-delay: -.7s;
        animation-delay: -.7s
    }

    ._1djK_:nth-child(6) {
        -webkit-animation-delay: -.6s;
        animation-delay: -.6s
    }

    ._1djK_:first-child:before {
        -webkit-animation-delay: -1.1s;
        animation-delay: -1.1s
    }

    ._1djK_:nth-child(2):before {
        -webkit-animation-delay: -1s;
        animation-delay: -1s
    }

    ._1djK_:nth-child(3):before {
        -webkit-animation-delay: -.9s;
        animation-delay: -.9s
    }

    ._1djK_:nth-child(4):before {
        -webkit-animation-delay: -.8s;
        animation-delay: -.8s
    }

    ._1djK_:nth-child(5):before {
        -webkit-animation-delay: -.7s;
        animation-delay: -.7s
    }

    ._1djK_:nth-child(6):before {
        -webkit-animation-delay: -.6s;
        animation-delay: -.6s
    }

@-webkit-keyframes _1SVAc {
    to {
        transform: rotate(1turn)
    }
}

@keyframes _1SVAc {
    to {
        transform: rotate(1turn)
    }
}

@-webkit-keyframes _1djK_ {
    80%,to {
        transform: rotate(1turn)
    }
}

@keyframes _1djK_ {
    80%,to {
        transform: rotate(1turn)
    }
}

@-webkit-keyframes _1292Q {
    50% {
        transform: scale(.4)
    }

    0%,to {
        transform: scale(1)
    }
}

@keyframes _1292Q {
    50% {
        transform: scale(.4)
    }

    0%,to {
        transform: scale(1)
    }
}
