html {
    background-color: transparent;
    color: black;
    font-family: "Georgia Black";
}


.mainPageText {
    text-align: center;
    padding-bottom: 20px;
    padding-top: 20px;
    color: black;
    font-family: "Georgia Light Black";
}

.mainPageTitle {
    font-size: x-large;
    text-align: center;
    padding-bottom: 20px;
    padding-top: 70px;
    color: black;
    font-family: "Georgia Light Black";
}
@media (max-width: 800px) {
    .iconTable {
        padding-top: 50px;
        padding-bottom: 100px;
        width: 55%;
    }

    .mainPageText {
        font-size: medium;
    }
}

@media only screen and (max-width: 600px) {
    .mintImage {
        width: 80% !important;
        height: auto;
        display: block;
        margin: auto;
        /*       margin-left: 30%;
        margin-right: 30%;*/
    }

    .mintTitle {
        margin-bottom: 0 !important;
        padding-bottom: 0 !important;
        color: black;
        font-size: 4vw;
        text-align: center;
        padding-bottom: 20px;
        /*        letter-spacing: 0.7vw;*/
        font-family: 'brandon-grotesque-black-58a8a3e824392';
    }
}

@media only screen and (min-width: 600px) {
    .mintImage {
        width: 40% !important;
        height: auto;
        margin: auto;
        margin-left: 30%;
        margin-right: 30%;
    }

    .mintTitle {
        margin-bottom: 0 !important;
        padding-bottom: 0 !important;
        color: black;
        font-size: 2vw;
        text-align: center;
        padding-bottom: 20px;
        /*        letter-spacing: 0.5vw;*/
        font-family: 'brandon-grotesque-black-58a8a3e824392';
    }
}

select#mintNoDD {
    max-width: 100px;
    width: 20% !important;
    border-radius: 10px;
    margin: auto;
    display: block;
    color: black;
    font-family: 'Brandon Grotesque', Calibri;
    font-size: medium;
    text-align: center;
    /*    margin-bottom: px;*/
}

.mintAScrambles {
    margin-bottom: 0 !important;
    padding-bottom: 10px !important;
    color: black;
    text-align: center;
    padding-bottom: 20px;
    padding-top: 50px;
    /*    letter-spacing: 0.3vw;*/
    /*    font-size: 2vw;*/
    font-family: 'brandon-grotesque-black-58a8a3e824392';
}

@media only screen and (max-width: 600px) {
    .mintAScrambles {
        /*        letter-spacing: 0.8vw;*/
        font-size: 6vw;
    }
}

@media only screen and (min-width: 600px) {
    .mintAScrambles {
        /*        letter-spacing: 0.3vw;*/
        font-size: 2vw;
    }
}

.mintSection {
    margin: auto;
    display: block;
    text-align: center;
    width: 60%;
    padding-top: 30px;
    padding-bottom: 30px;
    /*    font-family: 'Bebas Neue', cursive !important;*/
}

    .mintSection button {
        margin: auto;
        display: block;
        width: 100px;
        height: 50px;
        font-size: medium;
        font-style: oblique;
        border-radius: 10px;
        border-color: black;
        box-shadow: 0 .25rem .75rem rgba(255, 255, 255, .2);
        border: 1px solid black;
        background-color: transparent;
        color: black;
        visibility: visible !important;
    }

    .mintSection label {
        margin: auto;
        display: block;
        width: 50%;
        height: 50px;
        font-size: medium;
        font-style: oblique;
        border-radius: 10px;
        border-color: black;
        background-color: transparent;
        color: black;
        visibility: visible !important;
    }

.accountModal {
    width: 50%;
}

.errorMessage {
    text-align: center;
    font-family: 'brandon-grotesque-regular-italic-58a8a456c4724';
    width:60%;
}

.orderTotal {
    padding-bottom: 20px;
    padding-top: 20px;
    text-align: center;
    font-size: medium;
}

.boxOutline {
    border: solid black;
    width: fit-content;
    margin:auto;
    display: block;
    padding: 20px;
    padding-left: 40px;
    padding-right: 40px;
}

@media (min-width: 800px) {
    .iconTable {
        padding-top: 50px;
        padding-bottom: 100px;
        width: 15%;
    }

    .mainPageText {
        font-size: x-large;;
    }
}

.iconTable{

    display:block;
    margin:auto;
}

.iconTable td{
   width:28%;
}

.iconTable img{
    width:60%;
    display:block;
    margin:auto;
}


.scramblesMatrix img {
    width: 90% !important;
    height: auto !important;
    margin: 5% !important;
}

.scramblesMiniMatrix img {
    width: 90% !important;
    height: auto !important;
    margin: 5% !important;
}

.scramblesMatrix td {
    background: transparent;
    display: table-cell;
    min-width: 15vw !important;
    max-width: 15vw !important;
    width: 15vw !important;
}

@media (max-width: 800px) {
    .centerTable {
        width: 80%;
        display: block;
        margin: auto;
    }
}
@media (min-width: 800px) {
    .centerTable {
        width: 40%;
        display: block;
        margin: auto;
    }
}

    .inLineText {
        width: 40%;
        height: 100%;
        display: inline-block;
        padding: 0;
        margin-left: 5%;
        margin-right: 5%;
        vertical-align: middle;
    }

    .inLineTextSquare {
        width: 40%;
        display: inline-block !important;
        border: solid 2px black;
        vertical-align: middle;
        min-width: 43vw !important;
        max-width: 43vw !important;
        min-height: 43vw !important;
        max-height: 43vw !important;
        height: auto !important;
        height: 100% !important;
        /*    margin: 5px !important;*/
        float: right;
        margin-right: 5% !important;
        margin-top: 1% !important;
/*        margin-left: 5% !important;*/
    }

        .inLineTextSquare img {
            width: 100%;
            display: block;
            margin: auto;
            padding-left: 20%;
            padding-right: 20%;
        }

        .inLineTextSquare h1 {
            text-align: center;
            text-decoration: underline;
            font-size: 1.8vw;
            padding-bottom: 1.5vw;
            padding-top: 1vw;
            min-height: 1vw;
            height: auto;
        }

        .inLineTextSquare p {
            text-align: justify;
            font-size: 1.3vw;
            padding-bottom: 2vw;
            padding-left: 5%;
            padding-right: 5%;
            min-height: 2vw;
            height: auto;
            /*        padding-bottom: 30px;*/
        }

@media (max-width: 800px) {
    .inLineTextSquare p {
        font-size: 1.8vw;
        min-height: 6vw;
    }
}

    .times {
        font-family: 'Times New Roman' !important;
    }

    button.mmButtonIcon {
        background-color: transparent;
        border: none;
        vertical-align: central;
        margin: auto;
        padding-top: 15px;
        /*    height:100%;*/
        padding-left: 10px;
        color: transparent;
        /*    padding-top:14px;*/
    }

    .mmButtonIcon img, button.mmButtonIcon {
        margin-left: auto !important;
        margin-right: auto !important;
        display: block;
        /*            width: 100%;*/
        /*            height: 100%;*/
        color: black;
        /*            margin-bottom:5px;*/
        /*            height: 70px;*/
    }

        button.mmButtonIcon img {
            /*        background-color: violet;*/
            width: 1.5vw;
            height: 2vw;
        }

    .AddressText label {
        text-overflow: clip;
        width: 2px;
        font-size: smaller;
        margin-top: 10px;
    }

    .centralText {
        text-align: center;
        font-size: x-large;
    }

        .centralText p {
            padding-top: 10px;
            text-align: center;
            font-size: medium;
        }

        .centralText h3 {
            text-align: center;
            font-size: medium !important;
        }

    .centerButton {
        margin: auto;
        display: block;
        width: 100px;
        height: 50px;
        font-size: medium;
        font-style: oblique;
        border-radius: 10px;
        border-color: black;
        box-shadow: 0 .25rem .75rem rgba(255, 255, 255, .2);
        /*    background-color: rgb(35,31,32);*/
        background-color: transparent;
        color: black;
    }

    .sliderDiv {
        /*    border:solid black;*/
        width: 100%;
        margin-left: 0%;
        margin-right: 0%;
        /*    display:flex;*/
    }




    .minValLabel {
        float: left;
        /*    width:15%;*/
        /*    margin-right:0%;*/
        width: 12%;
    }

    .maxValLabel {
        float: right;
        /*    width: 15%;*/
        /*    margin-left: 0%;*/
        width: 22%;
        margin-left: 6px;
    }


    div.container {
        width: 100%;
        height: 100%;
        max-width: 100%;
        padding: 0px;
        scroll-snap-type: y mandatory;
        overflow-y: hidden;
        /*    margin:0px;*/
    }

    div.scrollTarget {
        scroll-snap-align: start;
        min-height: 50vh;
    }

    .errorMessage {
        width: 100%;
        margin: auto;
        text-align: center;
    }



    @media (min-width: 800px) {
        .footerTable {
            margin-top: 500px !important;
            margin-bottom: 200px !important;
            padding-bottom: 200px !important;
            /*            padding-top: 100px;*/
            width: 80%;
            margin-left: 10%;
            margin-right: 10%;
            font-family: 'Times New Roman' !important;
        }
    }

    @media (max-width: 800px) {
        .footerTable {
/*            margin-top: 200px !important;*/
            margin-bottom: 200px !important;
            padding-bottom: 200px !important;
            /*            padding-top: 200px;*/
            width: 100%;
            margin-left: 0%;
            margin-right: 0%;
            font-family: 'Times New Roman' !important;
        }
    }


    .footerTable td {
        width: 32%;
    }

    .footerTable img, .footerTable p {
        /*        display: inline-block;*/
        height: auto;
        width: 100%;
        margin: auto;
    }

    @media (min-width: 800px) {
        .footerTable p {
            margin-top: 30px;
            text-align: justify;
            min-width: 20px;
            max-width: 20vw;
            min-height: 100px;
            max-height: 20vw;
            font-size: 1vw;
            padding: 1vw;
        }
    }

    @media (max-width: 800px) {

        .footerTable p {
            margin-top: 30px;
            text-align: justify;
            min-width: 20px;
            max-width: 25vw;
            min-height: 100px;
            max-height: 20vw;
            font-size: 2vw;
            /*        padding: .5vw;*/
        }
    }

    .heroImage {
        display: inline-block;
        margin: auto;
        margin-left: 40%;
        margin-right: 40%;
        width: 20%;
        margin-top: 50px;
        margin-bottom: 50px;
    }

    @media (max-width: 800px) {

        .heroImage {
            margin-left: 5%;
            margin-right: 5%;
            width: 90%;
        }
    }


    .walletStuff {
        width: 100%;
        background-color: transparent;
        /*    margin: 10px auto;*/
        display: block;
        padding-bottom: 100px;
        padding-top: 0px;
        text-align: center;
    }

        .walletStuff button {
            /*    margin: auto;*/
            width: 120px;
            height: 50px;
            font-size: medium;
            font-style: oblique;
            border-radius: 10px;
            border-color: black;
            box-shadow: 0 .25rem .75rem rgba(255, 255, 255, .2);
            background-color: transparent;
            /*        background-color: rgb(35,31,32);*/
            color: black;
            border: 1px solid black;
            margin: auto;
            display: block;
        }

    .mintSection {
        margin: auto;
        display: block;
        text-align: center;
        width: 300px;
        padding-top: 30px;
    }

        .mintSection button {
            margin: auto;
            display: block;
            width: 100px;
            height: 50px;
            font-size: medium;
            font-style: oblique;
            border-radius: 10px;
            border-color: black;
            box-shadow: 0 .25rem .75rem rgba(255, 255, 255, .2);
            /*        background-color: rgb(35,31,32);*/
            border: 1px solid black;
            background-color: transparent;
            color: black;
            visibility: visible !important;
            margin-bottom:50px;
        }

        .mintSection label {
            margin: auto;
            display: block;
            width: 100px;
            height: 50px;
            font-size: medium;
            font-style: oblique;
            border-radius: 10px;
            border-color: black;
            box-shadow: 0 .25rem .75rem rgba(255, 255, 255, .2);
            background-color: transparent;
            border: 1px solid black;
            /*        background-color: rgb(35,31,32);*/
            color: black;
            visibility: visible !important;
        }

    .modalDiv {
        visibility: hidden;
    }

    .social-bar {
        background-color: rgba(0,0,0,0.11);
        /*    //padding: 0px 0px;*/
        position: fixed;
        bottom: 0px;
        /*    left: 38%;*/
        height: 40px;
        /*padding-left:40%;*/
        /*margin:auto;*/
        /*display:block;*/
        width: 100%;
        display: flex;
    }

    .openseaIcon {
        padding-top: 2px;
    }

    .social-button-container {
        /*    margin:auto;*/
        /*    display:block;*/
        display: block;
        /*    width:10%;*/
        padding-top: 10px;
        margin-left: auto;
        margin-right: auto;
        width: auto;
    }

    a.social {
        margin: 0 2rem;
        transition: transform 250ms;
        display: inline-block;
    }

    .auto-resizable-iframe {
        max-width: 100%;
        margin: 0px auto;
    }

        .auto-resizable-iframe > div {
            position: relative;
            padding-bottom: 75%;
            height: 0px;
        }

        .auto-resizable-iframe iframe {
            position: absolute;
            top: 0px;
            left: 0px;
            width: 100%;
            height: 100%;
        }


    div.imagepanel img, div.imagepanel iframe {
        width: 80%;
        height: auto;
        border: none;
        margin: auto;
        display: block;
    }

    .navBarLogo {
        width: 60px;
        height: 60px;
    }

    .navbar-toggler-icon {
        background-color: rgb(225,1,252);
        color: grey;
        border-radius: 5px;
    }

    .mini .menu-item a {
        color: black;
    }

    .wrapper {
        padding: 20px;
    }

    .largerLogo {
        width: 120px;
        height: 120px;
    }

    .centerInDiv {
        width: 100%;
    }


    button img {
        width: 40px;
        height: 40px;
        margin-right: 10px;
        border-radius: 5px;
    }

